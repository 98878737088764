export const TAB_FUEL_NAME = {
  CARBON_CREDIT: "Carbon Credit",
  SCOPE_1: "Scope 1",
  SCOPE_3: "Scope 3",
  PPA: "PPA",
  UNBUNDLED_RECS: "Unbundled RECs",
  ENERGY_LOAD: "Energy Load",
  GENERATED_ENERGY: "Generated Energy",
  RENEWABLE_ENERGY: "Renewable Energy",
} as {
  CARBON_CREDIT: string;
  SCOPE_1: string;
  SCOPE_3: string;
  PPA: string;
  UNBUNDLED_RECS: string;
  ENERGY_LOAD: string;
  GENERATED_ENERGY: string;
  RENEWABLE_ENERGY: string;
};

export enum HASH_FUEL_NAME {
  CARBON_CREDIT = "carbon_credit",
  SCOPE_1 = "scope_1",
  SCOPE_3 = "scope_3",
  PPA = "ppa",
  UNBUNDLED_RECS = "unbundled_recs",
  ENERGY_LOAD = "energy_load",
  GENERATED_ENERGY = "generated_energy",
  RENEWABLE_ENERGY = "renewable_energy",
}

export const TAB_TENANT_USER_DETAIL = {
  INFORMATION: "Information",
  //FACILITY_ROLE: "Facility Role",
  PASSWORD: "Password",
} as {
  INFORMATION: string;
  // FACILITY_ROLE: string;
  PASSWORD: string;
  [key: string]: string;
};

export enum HASH_TENANT_USER_DETAIL {
  INFORMATION = "information",
  //FACILITY_ROLE: "Facility Role",
  PASSWORD = "password",
}
