export const MONTHS = [
  {
    MonthName: "Jan",
  },
  {
    MonthName: "Feb",
  },
  {
    MonthName: "Mar",
  },
  {
    MonthName: "Apr",
  },
  {
    MonthName: "May",
  },
  {
    MonthName: "Jun",
  },
  {
    MonthName: "Jul",
  },
  {
    MonthName: "Aug",
  },
  {
    MonthName: "Sep",
  },
  {
    MonthName: "Oct",
  },
  {
    MonthName: "Nov",
  },
  {
    MonthName: "Dec",
  },
];
